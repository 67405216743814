import { useQuery } from '@tanstack/react-query';
import { minutes, seconds } from 'utils';
import { queries } from '../../../queries';
import { getPage } from './get-page';
import type { PageErrors, Page } from './types';

export function usePage<Select = Page>(props: Props<Select>) {
  const { locale, url, select } = props;
  //@ts-ignore
  return useQuery<Page, PageErrors, Select>(queries.cms.page(locale, url), getPage, {
    select,
    retry: 1,
    staleTime: minutes(5),
    cacheTime: minutes(10),
    retryDelay: seconds(10),
    refetchOnWindowFocus: true,
    keepPreviousData: true,
  });
}

interface Props<Select> {
  locale: string;
  url: string;
  select?: (data: Page) => Select;
}
