import { isBrowser, getHostKeyParam } from 'utils';

interface IProps {
    market: string;
    locale: string;
    productId: string;
    variantId: string;
    accessToken?: string;
}

export async function getProduct(
    { market, productId, variantId, accessToken, locale }: IProps
) {

    const prefixUrl = new URL(
        isBrowser ? '/api/catalogue/proxy' : '/api',
        isBrowser ? window.location.origin : process.env.COMMERCE_API_URL
    );

    const url = `${prefixUrl}/${market}/${locale}/catalogue/${productId}/${variantId}?${getHostKeyParam()}`

    const headers = new Headers({
        'Content-Type': 'application/json; charset=utf-8'
    });

    if (accessToken) {
        headers.append("Authorization", `Bearer ${accessToken}`);
    }

    const response = await fetch(url, {
        method: 'GET',
        headers,
        keepalive: true,
    });

    const { status, statusText } = response;

    if (status >= 500) {
        console.error(`Failed to fetch API with url:${url}`);
        console.error(statusText);
    }

    return response.json();
}
