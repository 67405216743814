import React, { FC, useEffect, useState } from 'react';
import { Button } from '../../../index';
import styles from './InputStepper.module.css';
import clsx from 'clsx';

interface IProps {
    colli?: number;
    defaultValue?: number;
    onChange?: (val: number) => void;
    small?: boolean;
    inputName?: string;
}

export const InputStepper: FC<IProps> = ({
    colli = 1,
    defaultValue,
    onChange,
    small,
    inputName = 'inputStepperValue'
}) => {
    const [value, setValue] = useState<number | null>(defaultValue || colli);

    useEffect(() => {
        defaultValue && setValue(defaultValue);
    }, [defaultValue]);

    const increment = () => {
        const newVal = value ? value + colli : colli;
        setValue(newVal);
        onChange && onChange(newVal);
    };

    const decrement = () => {
        if (!value) return;
        const newVal = Math.max(value - colli, colli);
        setValue(newVal);
        onChange && onChange(newVal);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = Number(e?.target?.value);
        setValue(inputValue);
        if (inputValue > 0) {
            onChange && onChange(inputValue);
        }
    };

    const handleOnBlur = () => {
        if (!value || value == 0) {
            setValue(colli);
            onChange && onChange(colli);
        }
    };

    const itemId = `${inputName}InputStepperValue`

    return (
        <div className={styles.root}>
            <Button
                aria-label="decrement basket value"
                size={small ? 'small' : 'medium'}
                className={clsx(
                    styles.button,
                    styles.minusButton,
                )}
                iconName='minus'
                variant='secondary'
                onClick={decrement}
            />
            <div className={small ? styles.inputWrapperSmall : styles.inputWrapperMedium}>
                {value}
                <label htmlFor={itemId} className={styles.inputLabel}>
                    Basket Value
                </label>
                <input
                    name={itemId}
                    id={itemId}
                    className={styles.input}
                    type="number"
                    value={value || ''}
                    onBlur={handleOnBlur}
                    onChange={handleInputChange}
                />
            </div>
            <Button
                aria-label="increment basket value"
                size={small ? 'small' : 'medium'}
                className={clsx(
                    styles.button,
                    styles.plusButton,
                )}
                iconName='plus' variant='secondary'
                onClick={increment}
            />
        </div>
    );
};