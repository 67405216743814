import { queries } from '../../../queries';
import type { Prefetch } from '../../../types';
import { getPage } from './get-page';
import type { PageErrors, Page } from './types';

export function prefetchPage({ locale, queryClientPage, url }: Props) {
  //@ts-ignore
  return queryClientPage.fetchQuery<Page, PageErrors>(queries.cms.page(locale, url), getPage);
}

interface Props extends Prefetch {
  url: string;
  locale: string;
}
