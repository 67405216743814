import { queries } from '../../../queries';
import type { PrefetchShared } from '../../../types';
import { getTranslations } from './get-translations';

export async function prefetchTranslations({ locale, queryClientPage, queryClientShared }: Props) {
  const key = queries.cms.translations(locale);

  await queryClientShared.fetchQuery(key, getTranslations);

  return queryClientPage.setQueryData(key, () => queryClientShared.getQueryData(key), {
    updatedAt: queryClientShared.getQueryState(key)?.dataUpdatedAt,
  });
}

interface Props extends PrefetchShared {
  locale: string;
}
