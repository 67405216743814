import type { QueryFunctionContext } from '@tanstack/react-query';
//@ts-ignore
//import { HTTPError } from 'ky';
//import { cmsApi } from '../../cms-api';
//import { FrameError } from './errors';
import type { FrameKey } from './types';
import { CmsModel } from 'api-types';
import { isBrowser, getHostKeyParam } from 'utils';

/*const exceptions = {
    500: FrameError,
};*/

export function getFrame(context: QueryFunctionContext<FrameKey>) {
    //const { signal, queryKey } = context;
    const { queryKey } = context;
    const [, , { locale }] = queryKey;

    /*const searchParams = new URLSearchParams();
    searchParams.set('url', '/' + locale);

   return cmsApi
        .get('content/frame', {
            signal,
            searchParams,
            throwHttpErrors: false,
            hooks: {
                afterResponse: [
                    (request, options, response) => {
                        if (!response.ok) {
                            const HTTPException =
                                exceptions[response.status as keyof typeof exceptions];

                            if (HTTPException) {
                                throw new HTTPException(response, queryKey);
                            }

                            throw new HTTPError(response, request, options);
                        }

                        return response;
                    },
                ],
            },
        })
        .json<CmsModel.Frame>();*/

    return fetchFrame(locale);
}

export async function fetchFrame(locale: string) {
    const prefixUrl = new URL(
        isBrowser ? '/api/proxy' : '/api',
        isBrowser ? window.location.origin : process.env.API_URL
    );

    console.log('FRAME: ', `${prefixUrl}/content/frame?url=/${locale}&${getHostKeyParam()}`);
    return fetcher<CmsModel.Frame>(`${prefixUrl}/content/frame?url=/${locale}&${getHostKeyParam()}`);
}

type Fetch<T> = Omit<Response, 'json'> & {
    json: () => Promise<T>;
};

/**
 * Helper method for fetching content from the API
 */
export async function fetcher<T>(url: string): Promise<Fetch<T>> {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
        },
        keepalive: true,
    });

    const { status, statusText } = response;

    if (status >= 500) {
        console.error(`Failed to fetch API with url:${url}`);
        console.error(statusText);
    }

    return response.json();
}
