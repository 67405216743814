import type { QueryFunctionContext } from '@tanstack/react-query';

import type { PageKey } from './types';
import { CmsModel } from 'api-types';
import { isBrowser, getHostKeyParam } from 'utils';

export function getPage(context: QueryFunctionContext<PageKey>) {
    const { queryKey } = context;
    const [, , { locale, url }] = queryKey;

    return fetchPage(`/${locale}${url}`);
}

export async function fetchPage(url: string) {
    const prefixUrl = new URL(
        isBrowser ? '/api/proxy' : '/api',
        isBrowser ? window.location.origin : process.env.API_URL
    );

    console.log("FETCH PAGE: ", `${prefixUrl}/content/page?url=${url}&${getHostKeyParam()}`);

    return fetcher<CmsModel.PageBase>(`${prefixUrl}/content/page?url=${url}&${getHostKeyParam()}`);
}

type Fetch<T> = Omit<Response, 'json'> & {
    json: () => Promise<T>;
};

/**
 * Helper method for fetching content from the API
 */
export async function fetcher<T>(url: string): Promise<Fetch<T>> {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
        },
        keepalive: true,
    });

    const { status, statusText } = response;

    if (status >= 500) {
        console.error(`Failed to fetch API with url:${url}`);
        console.error(statusText);
    }

    return response.json();
}
