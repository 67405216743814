import { useQuery } from '@tanstack/react-query';
import { minutes, seconds } from 'utils';
import { queries } from '../../../queries';
import { getTranslations } from './get-translations';
import type { Translations, TranslationsErrors } from './types';

export function useTranslations<Select = Translations>(props: Props<Select>) {
  const { locale, select } = props;

  return useQuery<Translations, TranslationsErrors, Select>(
    queries.cms.translations(locale),
    //@ts-ignore
    getTranslations,
    {
      select,
      retry: 1,
      staleTime: minutes(10),
      cacheTime: minutes(20),
      retryDelay: seconds(10),
      keepPreviousData: true,
      refetchOnWindowFocus: true,
    }
  );
}

interface Props<Select> {
  locale: string;
  select?: (data: Translations) => Select;
}
