import { useQuery } from '@tanstack/react-query';
import { minutes } from 'utils';
import { queries } from '../../../queries';
import { getFrame } from './get-frame';
import type { FrameErrors } from './types';
import { CmsModel } from 'api-types';

/**
 * Frame hook (footer, settings, and other things that are not page-specific).
 * If SSR data is not available, data will be fetched client side instead
 */
export function useFrame<Select = CmsModel.Frame>(props: Props<Select>) {
  const { locale, select } = props;
  //@ts-ignore
  return useQuery<CmsModel.Frame, FrameErrors, Select>(queries.cms.frame(locale), getFrame, {
    select,
    retry: false,
    staleTime: minutes(10),
    cacheTime: minutes(1),
    keepPreviousData: true,
    refetchOnWindowFocus: true,
  });
}

interface Props<Select> {
  locale: string;
  select?: (data: CmsModel.Frame) => Select;
}
